import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import waterfall from 'vue-waterfall2'
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

Vue.use(waterfall)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

