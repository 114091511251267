import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () =>import('../views/About.vue')
    
  },
  {
    path: '/newDynamic',
    name: 'newDynamic',
    component: () =>import('../views/newDynamic.vue')
    
  },
  {
    path: '/service',
    name: 'service',
    component: () =>import('../views/service.vue')
  },
  {
    path: '/caseShows',
    name: 'caseShows',
    component: () =>import('../views/caseShows.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: () =>import('../views/joinUs.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () =>import('../views/cooperation.vue')
  // cooperation
  }
]

const router = new VueRouter({
  routes
})

export default router
